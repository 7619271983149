'use client';
import Image from 'next/image';

import { useEffect } from 'react';

import notFoundBg from 'public/404.jpg';

export const NotFoundPage = () => {
  useEffect(() => {
    document.body.classList.add('h-full', 'overflow-hidden');
    document.getElementsByTagName('html')[0].classList.add('h-full');
  }, []);

  return (
    <main className="relative isolate min-h-full">
      <Image src={notFoundBg} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover object-top" />
      <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
        <p className="text-base font-semibold leading-8 text-white">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">Page not found</h1>
        <p className="mt-4 text-base text-white/70 sm:mt-6">
          Sorry, we couldn’t find the page you’re looking for. But its not too late for you to find Jesus!
        </p>

        <figure className="mx-auto max-w-screen-md text-center">
          <svg
            className="mx-auto mb-3 mt-10 h-10 w-10 text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 18 14"
          >
            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
          </svg>
          <blockquote>
            <p className="mx-auto w-80 text-2xl font-medium text-white">
              &ldquo; You will seek Me and find Me when you search for Me with all your heart. &rdquo;
            </p>
          </blockquote>
          <figcaption className="mt-6 flex items-center justify-center space-x-3">
            <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
              <cite className="pl-3 text-sm text-white">Jeremiah 29:13</cite>
            </div>
          </figcaption>
        </figure>

        <div className="mt-10 flex justify-center">
          <a href="/" className="text-sm font-semibold leading-7 text-white hover:text-gray-200">
            <span aria-hidden="true">&larr;</span> Back to home
          </a>
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
